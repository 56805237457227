










































































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref, Watch} from "vue-property-decorator";
import {DataDictionaryDtoPagedResultDto, FundDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import fundProject from "@/router/modules/fundProject";

@Component({
  name: "FundProjectList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectList extends Vue {
  @Ref() readonly pagedTableView!: any;
  tableItems: FundDto[] = [];
  statusList: any = [];
  projectType: any = [];
  fundId?: number = 0;

  queryForm: any = {
    name: "",
    projectType: "",
  };

  @Watch("$route.params")
  changeRoute(newVal: any) {
    this.pagedTableView!.fetchData();
  }

  created() {
    if (this.$route.params.fundId) {
      this.fundId = Number(this.$route.params.fundId);
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
    await api.enumService
      .getValues({
        typeName: "FundProjectType",
      })
      .then((res) => {
        this.projectType = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.$route.params.fundId) {
      params.fundId = Number(this.$route.params.fundId);
    }
    return api.fundProject.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({name: "fundProjectCreate"});
  }

  handleCustomForm() {
    this.$router.push({
      name: "customFormDesign",
      query: {
        hostType: "FundProject",
      },
    })
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.jumpDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.item);
        break;
      case "editApply":
        this.handleEditApply($row.item);
        break;
      case "delete":
        this.handleDelete($row.item.id);
        break;
      case "stickyPost":
        this.handleStickyPost($row.item.id);
        break;
      case "notStickyPost":
        this.handleNotStickyPost($row.item.id);
        break;
    }
  }

  handleMenuRight($row: any) {
    switch ($row.type) {
      case "donate":
        this.handleDonate($row.item.id);
        break;
      case "endReport":
        this.handleEndReport($row.item.id);
        break;
      case "news":
        this.handleNews($row.item);
        break;
      case "summary":
        this.handleSummary($row.item.id);
        break;
      case "start":
        this.handleEnableFundRaising($row.item.id);
        break;
      case "stop":
        this.handleDisableFundRaising($row.item.id);
        break;
      case "specialTrue":
        this.handleEnableFundSpecial($row.item.id)
        break;
      case "specialFalse":
        this.handleDisableFundSpecial($row.item.id)
        break;
      case "moneyFormEdit":
        this.handleMoneyFormEdit($row.item);
        break;
      case "report":
        this.handleReport($row.item.id);
        break;
      case "OfficialWebsiteView":
        this.handleOfficialWebsiteDisplay($row.item.id);
        break;
      case "OfficialWebsiteHide":
        this.handleOfficialWebsiteDisplay($row.item.id);
        break;
    }
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundProjectDetail",
      params: {id: row.id!.toString()},
    });
  }

  // 编辑
  handleEdit(row: FundDto) {
    this.$router.push({
      name: "fundProjectEdit",
      params: {id: row.id!.toString()},
    });
  }

  // 修改申请
  handleEditApply(row: FundDto) {
    this.$router.push({
      name: "fundProjectApplyEdit",
      params: {id: row.id!.toString()},
    });
  }

  handleNews(row: FundDto) {
    this.$router.push({
      name: "content-list-other",
      params: {
        fundProjectId: row.id!.toString(),
        fundId: "0",
      },
    });
  }

  // 删除
  async handleDelete(id: number) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fundProject.delete({id: id}).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 新增捐助
  handleDonate(id: number) {
    this.$router.push({
      name: "createDonateOrder",
      params: {id: id!.toString()},
    });
  }

  // 结项报告
  handleEndReport(id: number) {
    this.$router.push({
      name: "createEndReport",
      params: {id: id!.toString()},
    });
  }

  // 年度总结
  handleSummary(id: number) {
    this.$router.push({
      name: "fundProjectYearSummary",
      params: {fundProjectId: id!.toString()},
    });
  }

  // 相关报告
  handleReport(id: number) {
    this.$router.push({
      name: "fundProjectReport",
      params: {fundProjectId: id!.toString()},
    });
  }

  handleOfficialWebsiteDisplay(fundId: number) {
    this.$confirm("您确定要操作吗？", "提示").then(() => {
      api.fundProject.setOfficialWebsiteDisplay({body: {id: fundId}})
        .then(() => {
          this.$message.success("操作成功!");
          this.fetchData(this.queryForm);

          (this as any).$bus.$emit("refresh-data");
        });
    });
  }

  // 开启筹款
  handleEnableFundRaising(id: number) {
    this.$confirm("确定要开启筹款吗?", "提示").then(async () => {
      await api.fundProject.enableFundRaising({fundId: id}).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    });
  }

  // 关闭筹款
  handleDisableFundRaising(id: number) {
    this.$confirm("确定要关闭筹款吗?", "提示").then(async () => {
      await api.fundProject.disableFundRaising({fundId: id}).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    });
  }

// 开启公示
  handleEnableFundSpecial(id: number) {
    this.$confirm("确定要开启公示吗?", "提示").then(async () => {
      await api.fundProject.enableFundSpecial({fundId: id}).then(res => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    });
  }

  // 关闭公示
  handleDisableFundSpecial(id: number) {
    this.$confirm("确定要关闭公示吗?", "提示").then(async () => {
      await api.fundProject.disableFundSpecial({fundId: id}).then(res => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    });
  }

  handleMoneyFormEdit(row: FundDto) {
    this.$router.push({
      name: "fundProjectMoneyFormEdit",
      params: {id: row.id!.toString()},
    });
  }

  handleStickyPost(fundProjectId: number) {
    this.$confirm("您确定要置顶吗？", "提示").then(() => {
      api.fundProject.stickyPost({body: {id: fundProjectId}}).then(() => {
        this.$message.success("置顶成功!");
        this.fetchData(this.queryForm);
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  handleNotStickyPost(fundProjectId: number) {
    this.$confirm("您确定要取消置顶吗？", "提示").then(() => {
      api.fundProject.stickyPost({body: {id: fundProjectId}}).then(() => {
        this.$message.success("取消成功!");
        (this as any).$bus.$emit("refresh-data");
        this.fetchData(this.queryForm);
      });
    });
  }

  private cancel() {
    this.$router.back();
  }
}
